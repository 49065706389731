<template>
  <div>
    <NavComp />
    <div class="container">
      <SeasonComp />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SeasonComp from '@/components/Season/SeasonComp.vue';
import NavComp from "@/components/NavComp.vue";

export default {
  name: 'Season',
  components: {
    SeasonComp,
    NavComp,
  }
}
</script>
