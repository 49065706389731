<template>
    <div class="cardboard">
        <div v-for="season in seasonEndings" :key="season.id">
            <SeasonCardComp class="card" :gardentitle="season.data.gardenTitle" :yieldAmount="season.data.amount" :date="season.data.date" :fertilizers="season.data.fertilizers" />
        </div>
    </div>
</template>

<script>
import SeasonCardComp from "@/components/Season/SeasonCardComp.vue";

import { getAllSeasonEndings } from "../../db/dbMethods.js";

export default {
    name: "SeasonComp",
    data() {
        return {
            seasonEndings: [],
        }
    },
    components: {
        SeasonCardComp
    },
    async created() {
        this.seasonEndings = await getAllSeasonEndings();
    }
}
</script>

<style scoped>
.cardboard{
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr;
}

.card {
    margin: 20px;
}

@media screen and (max-width: 1024px){
    .cardboard{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 480px){
    .cardboard{
        grid-template-columns: 1fr;
    }
}
</style>