<template>
    <section>
        <div class="">
            <div class="garden-title" >{{ gardentitle }} </div>
            <div class="garden-info"><b>{{ $t("endings.card.yield") }}</b>: {{yieldAmount}}kg </div>
            <div class="garden-info"><b>{{ $t("endings.card.end") }}</b>: {{dateToString}} </div>
            <div class="garden-info fertilizer-title"><b>{{ $t("endings.card.fertilizers")}}</b>: 
              <div v-for="(fertilzer,index) in fertilizers" :key="index">
                <p class="fertilizer">{{fertilzer}}</p>
              </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: "SeasonCardComp",
    data() {
        return {
        }
    },
    props: {
      gardentitle: String,
      yieldAmount: Number,
      date: String,
      fertilizers: Array,
    },
    methods: {
    },
    computed: {
      dateToString: function () {
        const toArr = this.date.toString().split(" ");
        const newString = `${toArr[0]} ${toArr[2]} ${toArr[1]} ${toArr[3]}     ${toArr[4]}`
        return newString
      }, 
    }
}
</script>

<style scoped>

.garden-title{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
  background: rgb(121, 190, 121);
}

.garden-info{
  font-size: 0.8em;
  font-size: 16px;
  margin-left: 32px;
}

.garden-area {
  font-weight: bolder;
}

.fertilizer {
  margin-left: 40px;
}

.fertilizer-title{
  margin-bottom: 8px;
}

@media screen and (max-width: 635px){
  .garden-info{
    margin-left: 8px;
  }
}

@media screen and (max-width: 580px){
  .garden-info{
    font-size: 13px;
  }
}

@media screen and (max-width: 480px){
  .garden-info{
    font-size: 16px;
    margin-left: 40px;
  }
}

</style>